// import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";
// import { has } from "lodash";
import { ToastProgrammatic as Toast } from "buefy";
import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "pspdList";
const modelPath = "/stase/mahasiswa/";

class PSPDList extends ListModelBase {
  constructor(staseId) {
    super(modelName, modelPath, {stase_id: staseId});
  }

  delete(id, onDeleted, params = {}) {
    const url = `${this.apiURL}${id}/`;
    this.observables.loading = true;
    axios.delete(url, { params: params })
      .then(() => {
        Toast.open("Data berhasil di hapus.");
        if (onDeleted) {
          onDeleted();
        }
      })
      .catch(() => {
        Toast.open("Gagal menghapus.");
      })
      .finally(() => {
        this.observables.loading = false;
      });
  }
}

export default PSPDList;
